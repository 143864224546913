import React, { Component } from "react"
import PageTitleArea from "../components/Common/PageTitleArea"
import { graphql } from "gatsby"
// import FreeTrialArea from '../components/Common/FreeTrialArea';
import { Link } from "gatsby"
import Layout from "../components/layout"
import BlockContent from "../components/blockContent"
import SegmentRenderer from "../components/segmentRenderer"

export const query = graphql`
query privacyPageQuery {
  page: sanityPageWithContent(_id: {eq: "privacyPage"}) {
    seo {
      title
      image {
        asset {
          url
        }
      }
      description
    }
    _rawContent(resolveReferences: {maxDepth: 10})
  }
}
`

const PrivacyPolicy = props => {
  const { page } = props.data
  const seo = props.data?.page?.seo
  return (
    <Layout title={seo.title} description={seo.description} image={seo.image}>
      <PageTitleArea pageTitle="Privacy Policy" />

      <div className="text-container ptb-100">
        <div className="container">
          <SegmentRenderer content={page._rawContent} />
        </div>
      </div>

    </Layout>
  )
}

export default PrivacyPolicy
